import React, { Component } from "react";
import { NavLink as RRNavLink, Route } from "react-router-dom";
import DeviceDetails from "./deviceDetails";
import DistributorDevices from "./distributorDevices";
import GroupDevices from "./groupDevices";
import UserDevices from "./userDevices";


class Devices extends Component {
  render() {
    return (
      <div>
        <h5>
          <RRNavLink exact to="/devices" activeStyle={{backgroundColor: '#CCC', padding: '5px'}}>
            <u>Device Details</u>
          </RRNavLink>
          <span> | </span>
          <RRNavLink to="/devices/per-distributor" activeStyle={{backgroundColor: '#CCC', padding: '5px'}}>
            <u>Distributor Devices</u>
          </RRNavLink>
          <span> | </span>
          <RRNavLink to="/devices/per-group" activeStyle={{backgroundColor: '#CCC', padding: '5px'}}>
            <u>Group Devices</u>
          </RRNavLink>
          <span> | </span>
          <RRNavLink to="/devices/per-user" activeStyle={{backgroundColor: '#CCC', padding: '5px'}}>
            <u>User Devices</u>
          </RRNavLink>
        </h5>
        <hr />
        <div>
          <Route exact path="/devices" component={DeviceDetails}/>
          <Route path="/devices/per-distributor" component={DistributorDevices}/>
          <Route path="/devices/per-group" component={GroupDevices}/>
          <Route path="/devices/per-user" component={UserDevices}/>
        </div>
      </div>
    );
  }
}

export default Devices;
