import React, { Component } from "react";
import {
  Container, Col, Row,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Badge, Button
} from 'reactstrap';
import { APIClient } from "../Client";


class GroupDevices extends Component {
  constructor(props) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      distributors: undefined,
      distributor: undefined,
      distributor_devices: undefined,
      groups: undefined,
      group_devices: {},
      dropdownOpen: false
    };
  }
  
  componentDidMount() {
    APIClient.then(() => {
      this.setState({
        distributors: APIClient.entities.distributors,
        groups: APIClient.entities.groups
      });
    });
  }
  
  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  getGroupDevices = (groupId) => {
    APIClient.api['group-devices'].list_group_devices({groupId: groupId})
      .then((res) => {
        //console.log('GET:', res.url);
        let gd = res.obj.data; // group-devices
        this.setState(prevState => ({
          group_devices: { ...prevState.group_devices, [gd.groupId]: gd.devices }
        }));
        //console.log('group devices', this.state.group_devices);
      })
      .catch((err) => {
        //console.log(err.response.data);
        this.setState(prevState => ({
          group_devices: { ...prevState.group_devices, [groupId]: {} }
        }));
      });
  }

  getGroupsDevices = (groups) => {
    //console.log(groups.success, groups);
    if (groups.success !== true) {
      return;
    }
    this.setState({group_devices: {}});
    groups.data.groups.forEach(gid => {
      this.getGroupDevices(gid);
    });
  }

  getDistributorGroupsAndDevices = (distributor) => {
    // console.log(distributor.distributorId, distributor.name);
    this.setState({
        distributor: {name: '(retrieving...)'},
        group_devices: {}
    });

    APIClient.api['distributor-groups'].list_distributor_groups({distributorId: distributor.distributorId})
      .then((res) => {
        //console.log('GET:', res.url);
        this.getGroupsDevices(res.obj);
      })
      .catch((err) => {
        console.log(err.response.data);
      });

    APIClient.api['distributor-devices'].list_distributor_devices({distributorId: distributor.distributorId})
      .then((res) => {
        //console.log('GET:', res.url);
        if (res.obj.success === true) {
          this.setState({
            distributor: distributor,
            distributor_devices: res.obj.data.devices
          });
        }
        //console.log('distributor devices', this.state.distributor_devices);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  addGroupDevice = (groupId, type, serial) => {
    //onsole.log('add', groupId, type, serial);
    if (!groupId) {
      return; // not valid
    }
    
    APIClient.client.execute({
      operationId: 'add_group_devices',
      parameters: {
        groupId: groupId,
        groupDevicesList: {[type]: [serial]}
      },
      requestContentType: 'application/json'})
      .then((res) => {
        //console.log(res.obj);
        this.getGroupDevices(groupId);
      })
      .catch((err) => {
        console.log(err.response.obj);
      });

  }

  removeUserDevice = (groupId, type, serial) => {
    //console.log('remove', groupId, type, serial);
    if (!groupId) {
      return; // not valid
    }

    APIClient.client.execute({
      operationId: 'remove_group_devices',
      parameters: {
        groupId: groupId,
        groupDevicesList: {[type]: [serial]}
      },
      requestContentType: 'application/json'})
      .then((res) => {
        //console.log(res.obj);
        this.getGroupDevices(groupId);
      })
      .catch((err) => {
        console.log(err.response.obj);
      });
  }

  onDragStart = (ev, type, serial, groupId) => {
    //console.log('drag start', type, serial, userid);
    ev.dataTransfer.setData("src", JSON.stringify({t: type, s: serial, g: groupId}));
  }

  onDragOver = (ev) => {
    //console.log('drag over');
    ev.preventDefault();
  }

  onDrop = (ev, g) => {
    let src = JSON.parse(ev.dataTransfer.getData("src"));
    if (g === src.g) {
      return; // no change
    }
    //console.log('drop', src.t, src.s, 'from', src.g, 'to', g);
    this.addGroupDevice(g, src.t, src.s);
    this.removeUserDevice(src.g, src.t, src.s);
  }

  DeviceDiv = ({type, serial, groupId}) => (
    <div className="col-sm" draggable
      style={{height: '2em', maxWidth: '14em', margin: '2px', backgroundColor: 'skyblue'}}
      onDragStart={(e) => this.onDragStart(e, type, serial, groupId)}>
      <p className="text-center">{serial}</p>
    </div>
  );

  DevicesDiv = ({devices, groupId}) => (
    <Row>
      { devices.powersave && (devices.powersave.length > 0) &&
      <Col style={{margin: '4px', border: '1px dotted blue'}}>
        <h6>PowerSave</h6>
        <Row>
        {devices.powersave.map(serial => (
          <this.DeviceDiv key={serial} type="powersave" serial={serial} groupId={groupId}/>
        ))}
        </Row>
      </Col>
      }
      { devices.edgeiq && (devices.edgeiq.length > 0) &&
      <Col style={{margin: '4px', border: '1px dotted blue'}}>
        <h6>EdgeIQ</h6>
        <Row>
        {devices.edgeiq.map(serial => (
          <this.DeviceDiv key={serial} type="edgeiq" serial={serial} groupId={groupId}/>
        ))}
        </Row>
      </Col>
      }
      { devices.esensor && (devices.esensor.length > 0) &&
      <Col style={{margin: '4px', border: '1px dotted blue'}}>
        <h6>eSensor</h6>
        <Row>
        {devices.esensor.map(serial => (
          <this.DeviceDiv key={serial} type="esensor" serial={serial} groupId={groupId}/>
        ))}
        </Row>
      </Col>
      }
    </Row>
  );

  GroupDevicesDiv = ({group, devices}) => (
    <div style={{margin: '4px', padding: '0 16px', border: '1px solid blue'}}
      onDragOver={(e) => this.onDragOver(e)}
      onDrop={(e) => this.onDrop(e, group.groupId)}>
      <div data-toggle="tooltip" title={group.description}>
        <span>{group.name} <Badge>{group.groupId}</Badge></span>
      </div>
      <this.DevicesDiv devices={devices} groupId={group.groupId}/>
    </div>
  );

  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );
    if (!this.state.distributors)
      return (
        <Button color="link" onClick={() => this.props.history.push('/distributors')}>Get Distributors</Button>
      );
    if (!this.state.groups)
      return (
        <Button color="link" onClick={() => this.props.history.push('/groups')}>Get Groups</Button>
      );

    return (
      <Container fluid>
        <Row>
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
            <DropdownToggle caret>
              { this.state.distributor ? this.state.distributor.name :'select distributor'}
            </DropdownToggle>
            <DropdownMenu style={{maxHeight: '50vh', overflow: 'auto'}}>
              { this.state.distributors.map((distributor, index) => (
                <DropdownItem key={distributor.distributorId}
                  onClick={() => this.getDistributorGroupsAndDevices(distributor)}>{distributor.name}
                </DropdownItem>
              )) }
            </DropdownMenu>
          </Dropdown>
        </Row>
        { this.state.distributor && this.state.groups &&
        <Row>
          <Col>
            <Row><h5>Group Devices</h5></Row>
            { (Object.keys(this.state.group_devices).length > 0) &&
            <div>
              { Object.keys(this.state.group_devices).map((groupId, index) => (
                <this.GroupDevicesDiv key={index}
                    group={this.state.groups.find(g => g.groupId === groupId)}
                    devices={this.state.group_devices[groupId]} />
              ))
              }
            </div>
            }
          </Col>
          <Col onDragOver={(e) => this.onDragOver(e)}
            onDrop={(e) => this.onDrop(e, undefined)}>
            <Row><h5>{this.state.distributor.name} Devices</h5></Row>
            { this.state.distributor_devices &&
            <this.DevicesDiv devices={this.state.distributor_devices} />
            }
          </Col>
        </Row>
        }
      </Container>
    );
  }
}

export default GroupDevices;
