const Swagger = require('swagger-client');

var APIClient = new Swagger({
  //url: 'http://localhost:8082/v2/swagger.json',
  url: 'https://rest.edgecustomerportal.com/v2/swagger.json',
  
  //requestInterceptor(req) {
  //  console.log(req.body, req.headers);
  //  req.headers['content-type'] = 'application/json';
  //  return req;
  //},

  authorizations: {
    OAuth2: { token: { access_token: ''} }
  },
}).then((client) => {
  APIClient.client = client;
  APIClient.api = client.apis;
  APIClient.client.spec.schemes = [client.url.split(':')[0]];
  //console.log('client ready');

  APIClient.entities = {};

  APIClient.storeToken = function(token) {
    if ('token' in APIClient) {
      delete APIClient.token;
    }
    APIClient.token = token;
    APIClient.client.authorizations.OAuth2.token.access_token = token.accessToken;
    token.account = { email: token.account.email }; // discard other info
    sessionStorage.setItem('apitoken', JSON.stringify(token));
  };

  APIClient.storeDistributors = function(distributors) {
    if ('distributors' in APIClient.entities) {
      delete APIClient.entities.distributors;
      //sessionStorage.removeItem('entdistributors');
    }
    APIClient.entities.distributors = distributors.sort((a, b) => a.distributorId - b.distributorId);
    sessionStorage.setItem('entdistributors', JSON.stringify(distributors));
  };

  APIClient.storeGroups = function(groups) {
    if ('distributors' in APIClient.entities) {
      delete APIClient.entities.groups;
      //sessionStorage.removeItem('entgroups');
    }
    APIClient.entities.groups = groups.sort((a, b) => a.groupId - b.groupId);
    sessionStorage.setItem('entgroups', JSON.stringify(groups));
  };

  APIClient.storeUsers = function(users) {
    if ('users' in APIClient.entities) {
      delete APIClient.entities.users;
      //sessionStorage.removeItem('entusers');
    }
    APIClient.entities.users = users.sort((a, b) => a.id - b.id);
    sessionStorage.setItem('entusers', JSON.stringify(users));
  };

  var token = JSON.parse(sessionStorage.getItem('apitoken'));
  if (token) {
    APIClient.storeToken(token);
  }

  APIClient.entities.distributors = JSON.parse(sessionStorage.getItem('entdistributors'));
  APIClient.entities.groups = JSON.parse(sessionStorage.getItem('entgroups'));
  APIClient.entities.users = JSON.parse(sessionStorage.getItem('entusers'));

}, (error) => {
  console.error('failed to load api spec: %o', error);
});

export { APIClient };
