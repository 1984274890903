import React, { Component } from "react";
import {
  Container, Col, Row,
  Badge, Button
} from 'reactstrap';
import { APIClient } from "../Client";


class GroupUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distributor_users: {},
      groups_users: {},
      ready: false
    };
  }

  componentDidMount() {
    APIClient.then(() => {
      this.getDistributorUsers();
      this.getGroupUsers();
      this.setState({ ready: true });
    });
  }

  getDistributorUsers = () => {
    if (!APIClient.token || !APIClient.entities.distributors) {
      return;
    }
    if (this.state && (Object.keys(this.state.distributor_users).length > 0)) {
      return;
    }
    APIClient.entities.distributors.forEach((d) => {
      APIClient.api['distributor-users'].list_distributor_users({ distributorId : d.distributorId})
        .then((res) => {
          //console.log(res.obj.data);
          let du = res.obj.data; // distributor-users
          this.setState(prevState => ({
            distributor_users: { ...prevState.distributor_users, [du.distributorId]: du.users }
          }));
        })
        .catch((err) => {
          console.log(err.response, err);
        });
    });
  }

  getGroupUsers = () => {
    if (!APIClient.token || !APIClient.entities.groups) {
      return;
    }
    if (this.state && (Object.keys(this.state.groups_users).length > 0)) {
      return;
    }
    APIClient.entities.groups.forEach((g) => {
      APIClient.api['group-users'].list_group_users({ groupId : g.groupId})
        .then((res) => {
          //console.log(res.obj.data);
          let gu = res.obj.data; // groups_users
          this.setState(prevState => ({
            groups_users: { ...prevState.groups_users, [gu.groupId]: gu.users }
          }));
        })
        .catch((err) => {
          console.log(err.response, err);
        });
    });
  }

  addGroupUser = (groupId, userId) => {
    //console.log('add', userId, 'to', groupId);
    if (!groupId) {
      return; // not valid
    }
    
    let users = this.state.groups_users[groupId];
    if (!users || (users.indexOf(userId) >= 0)) {
      return; // already existing
    }

    APIClient.client.execute({
      operationId: 'add_group_users',
      parameters: {
        groupId: groupId,
        groupUsersList: { groupId: groupId, users: [userId] }
      },
      requestContentType: 'application/json'})
      .then((res) => {
        //console.log(res.obj.data);
        users.push(userId);
        this.setState(prevState => ({
          groups_users: { ...prevState.groups_users, [groupId]: users }
        }));
      })
      .catch((err) => {
        console.log(err.response.obj);
      });
  }

  removeGroupUser = (groupId, userId) => {
    //console.log('remove', userId, 'from', groupId);
    if (!groupId) {
      return; // not valid
    }
    
    let users = this.state.groups_users[groupId];
    if (!users || (users.indexOf(userId) < 0)) {
      return; // not existing
    }

    APIClient.client.execute({
      operationId: 'remove_group_users',
      parameters: {
        groupId: groupId,
        groupUsersList: { groupId: groupId, users: [userId] }
      },
      requestContentType: 'application/json'})
      .then((res) => {
        //console.log(res.obj.data);
        users.splice(users.indexOf(userId), 1); // delete id
        this.setState(prevState => ({
          groups_users: {...prevState.groups_users, [groupId]: users }
        }));
      })
      .catch((err) => {
        console.log(err.response.obj);
      });
  }

  onDragStart = (ev, groupId, userId) => {
    //console.log('drag start', groupId, userId);
    ev.dataTransfer.setData("src", JSON.stringify({d: groupId, u: userId}));
  }

  onDragOver = (ev) => {
    //console.log('drag over');
    ev.preventDefault();
  }

  onDrop = (ev, d) => {
    let src = JSON.parse(ev.dataTransfer.getData("src"));
    //console.log('drop', src.u, 'from', src.d, 'to', d);
    if (d === src.d) {
      return; // no change
    }
    this.addGroupUser(d, src.u);
    this.removeGroupUser(src.d, src.u);
  }

  UserDiv = ({user}) => (
    <div className="col-sm" draggable
      style={{height: '3em', maxWidth: '10em', margin: '2px', backgroundColor: 'skyblue'}}
      data-toggle="tooltip" title={user.email + ' (' + user.firstName + ' ' + user.lastName + ')'}
      onDragStart={(e) => this.onDragStart(e, user.groupId, user.id)}>
      <p style={{overflowWrap: 'break-word'}}>
        <Badge>{user.id}</Badge><small>{user.email.split('@')[0]}</small>
      </p>
    </div>
  );

  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );

    if (!APIClient.entities.distributors)
      return (
        <Button color="link" onClick={() => this.props.history.push('/distributors')}>Get Distributor List</Button>
      );

    if (!APIClient.entities.groups)
      return (
        <Button color="link" onClick={() => this.props.history.push('/groups')}>Get Group List</Button>
      );

    if (!APIClient.entities.users)
      return (
        <Button color="link" onClick={() => this.props.history.push('/users')}>Get User List</Button>
      );

    if (!this.state || !this.state.distributor_users || !this.state.groups_users ||
        (Object.keys(this.state.groups_users).length !== APIClient.entities.groups.length))
      return (
        <div>Loading . . .</div>
      );

    var associated_users = {};

    //console.log(this.state.groups_users);

    Object.keys(this.state.groups_users).forEach((d) => {
      //console.log(d, this.state.groups_users[d]);
      if (!(d in associated_users)) {
        associated_users[d] = [];
      }
      this.state.groups_users[d].forEach((u) => {
        let users = JSON.parse(JSON.stringify(APIClient.entities.users)); // clone
        let user = users.find(o => o.id === u);
        //console.log(u, user);
        user.groupId = d;
        associated_users[d].push(
          <this.UserDiv user={user} key={u}/>
        );
      });
    });

    //console.log(associated_users);

    return (
      <Container fluid style={{height: '85vh'}}>
        <Row style={{height: '100%'}}>
          <Col style={{height: '100%'}}>
            <h5 className="text-center">Groups</h5>
            <div className="col" style={{height: '96%', overflow: 'auto'}}>
              { APIClient.entities.groups.map(group => (
              <div className="row" key={group.groupId}
                onDragOver={(e) => this.onDragOver(e)}
                onDrop={(e) => this.onDrop(e, group.groupId)}
                style={{margin: '8px', border: '1px solid blue'}}>
                <div className="col-sm-12 text-center"
                  data-toggle="tooltip" title={group.description}>
                  <strong>{group.name}</strong> <span>  </span>
                  <Badge color="info">ID {group.groupId}</Badge>
                </div>
                {associated_users[group.groupId]}
              </div>
              )) }
            </div>
          </Col>
          <Col style={{height: '100%'}}>
            <h5 className="text-center">Distributor Users</h5>
            <div className="col" style={{height: '96%', overflow: 'auto'}}>
              { APIClient.entities.distributors.map(distributor => (
                <div className="row" key={distributor.distributorId}
                  onDragOver={(e) => this.onDragOver(e)}
                  onDrop={(e) => this.onDrop(e, undefined)}
                  style={{margin: '8px', border: '1px solid blue'}}>
                  <div className="col-sm-12 text-center"
                    data-toggle="tooltip" title={distributor.description}>
                    <strong>{distributor.name}</strong> <span>  </span>
                    <Badge color="info">ID {distributor.distributorId}</Badge>
                  </div>
                  {this.state.distributor_users[distributor.distributorId].map(userId => (
                    <this.UserDiv key={userId}
                      user={APIClient.entities.users.find(u => u.id === userId)} />
                  ))}
                </div>
              )) }
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default GroupUsers;
