import React, { Component } from 'react';
import {
  Route,
  NavLink as RRNavLink,
  BrowserRouter
} from "react-router-dom";
import {
  Navbar, NavbarBrand, NavbarToggler,
  Nav, NavItem, NavLink,
  Collapse, Button
} from 'reactstrap';

import { APIClient } from "./Client";
import Home from "./Home"
import Distributors from "./entities/distributors"
import Groups from "./entities/groups"
import Users from "./entities/users"
import Devices from "./entities/devices"


class App extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.redrawHandler = this.redrawHandler.bind(this);
    this.state = {
      isOpen: false
    };
  }
  
  componentDidMount() {
    APIClient.then(() => {
      this.setState(this.state);
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  redrawHandler() {
    this.setState(this.state);
  }

  signOut() {
    if (APIClient.token) {
      APIClient.api['api-users'].sign_out()
        .then((res) => {
          sessionStorage.clear();
          delete APIClient.token;
          delete APIClient.client.authorizations.OAuth2.token.access_token;
          this.setState(this.state);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <Navbar color="light" light expand="md">
            <NavbarBrand exact to="/" activeClassName="active" tag={RRNavLink}>Edge API Admin</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
            { APIClient.token && APIClient.token.account &&
              <Nav className="ml-auto" navbar>
                <NavItem><NavLink to="/distributors" activeClassName="active" tag={RRNavLink}>Distributors</NavLink></NavItem>
                <NavItem><NavLink to="/groups" activeClassName="active" tag={RRNavLink}>Groups</NavLink></NavItem>
                <NavItem><NavLink to="/users" activeClassName="active" tag={RRNavLink}>Users</NavLink></NavItem>
                <NavItem><NavLink to="/devices" activeClassName="active" tag={RRNavLink}>Devices</NavLink></NavItem>
                <NavItem><span> {APIClient.token.account.email}</span><Button color="link" onClick={() => this.signOut()}>Sign-out</Button></NavItem>
              </Nav>
            }
            </Collapse>
          </Navbar>
          <div className="content">
            <Route exact path="/" render={(props) => <Home {...props} redrawParent={this.redrawHandler} />}/>
            <Route path="/distributors" component={Distributors}/>
            <Route path="/groups" component={Groups}/>
            <Route path="/users" component={Users}/>
            <Route path="/devices" component={Devices}/>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
