import React, { Component } from "react";
import {
  Container, Col, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Form, FormGroup, Button, Input, Label
} from 'reactstrap';
import { APIClient } from "../Client";


class DistributorDevices extends Component {
  constructor(props) {
  super(props);
  this.toggleDropdown = this.toggleDropdown.bind(this);
  this.state = {
    distributors: undefined,
    distributor: undefined,
    distributor_devices: undefined,
    dropdownOpen: false
  };
  }

  componentDidMount() {
    APIClient.then(() => {
      this.setState({ distributors: APIClient.entities.distributors });
    });
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  getDistributorDevices = (distributor) => {
    // console.log(distributor.distributorId, distributor.name);
    this.setState({distributor: {name: '(retrieving...)'}});
    APIClient.api['distributor-devices'].list_distributor_devices({distributorId: distributor.distributorId})
      .then((res) => {
        //console.log('GET:', res.url);
        this.setState({distributor: distributor, distributor_devices: res.obj});
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  addDistributorDevices = (event) => {
    event.preventDefault();
    if (!this.state.distributor) {
      return;
    }

    var parameters = {
      distributorId: this.state.distributor.distributorId,
      distributorDevicesList: {
        edgeiq: event.target.edgeiq.value.split(/\s+/).filter(x => x),
        esensor: event.target.esensor.value.split(/\s+/).filter(x => x),
        powersave: event.target.powersave.value.split(/\s+/).filter(x => x)
      }
    }

    //console.log('add', parameters);

    APIClient.client.execute({
      operationId: 'add_distributor_devices',
      parameters: parameters,
      requestContentType: 'application/json'})
      .then((res) => {
        console.log('PATCH:', res.url);
        //this.setState({distributor_devices: res.obj});
        this.getDistributorDevices(this.state.distributor);
      })
      .catch((err) => {
        console.log(err.response, err);
      });
  }

  removeDistributorDevices = (event) => {
    event.preventDefault();
    if (!this.state.distributor) {
      return;
    }

    var parameters = {
      distributorId: this.state.distributor.distributorId,
      distributorDevicesList: {
        edgeiq: event.target.edgeiq.value.split(/\s+/).filter(x => x),
        esensor: event.target.esensor.value.split(/\s+/).filter(x => x),
        powersave: event.target.powersave.value.split(/\s+/).filter(x => x)
      }
    }

    //console.log('remove', parameters);

    APIClient.client.execute({
      operationId: 'remove_distributor_devices',
      parameters: parameters,
      requestContentType: 'application/json'})
      .then((res) => {
        //console.log('DELETE:', res.url);
        //this.setState({distributor_devices: res.obj});
        this.getDistributorDevices(this.state.distributor);
      })
      .catch((err) => {
        console.log(err.response, err);
      });
  }

  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );
    if (!this.state.distributors)
      return (
        <Button color="link" onClick={() => this.props.history.push('/distributors')}>Get Distributors</Button>
      );

    var devices = this.state.distributor_devices ? this.state.distributor_devices.data : undefined;
    return (
      <Container fluid>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret>
            {this.state.distributor ? this.state.distributor.name :'select distributor'}
          </DropdownToggle>
          <DropdownMenu style={{maxHeight: '50vh', overflow: 'auto'}}>
            { this.state.distributors.map((distributor, index) => (
              <DropdownItem key={distributor.distributorId}
                onClick={() => this.getDistributorDevices(distributor)}>{distributor.name}
              </DropdownItem>
            )) }
          </DropdownMenu>
        </Dropdown>
        { devices && devices.devices &&
        <div className="table-responsive" style={{display: 'block', maxWidth: '60vw', maxHeight: '50vh', overflow: 'auto'}}>
          <Table bordered hover size="sm">
            <caption>Distributor ID {devices.distributorId}</caption>
            <thead>
              <tr><th>PowerSave</th><th>EdgeIQ</th><th>eSensor</th></tr>
            </thead>
            <tbody>
              <tr style={{whiteSpace: 'pre'}}>
                <td>{devices.devices.powersave.toString().replace(",", "\n")}</td>
                <td>{devices.devices.edgeiq.toString().replace(",", "\n")}</td>
                <td>{devices.devices.esensor.toString().replace(",", "\n")}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        }
        <Form>
          <FormGroup row>
            <Label sm={2}>PowerSave:</Label>
            <Label sm={2}>EdgeIQ:</Label>
            <Label sm={2}>eSensor:</Label>
          </FormGroup>
        </Form>
        <Form  onSubmit={this.addDistributorDevices}>
          <FormGroup row>
            <Col sm={2}><Input type="textarea" name="powersave" rows='1'></Input></Col>
            <Col sm={2}><Input type="textarea" name="edgeiq" rows='1'></Input></Col>
            <Col sm={2}><Input type="textarea" name="esensor" rows='1'></Input></Col>
            <Col><Button type="submit" color="primary">Add Devices</Button></Col>
          </FormGroup>
        </Form>
        <Form  onSubmit={this.removeDistributorDevices}>
          <FormGroup row>
            <Col sm={2}><Input type="textarea" name="powersave" rows='1'></Input></Col>
            <Col sm={2}><Input type="textarea" name="edgeiq" rows='1'></Input></Col>
            <Col sm={2}><Input type="textarea" name="esensor" rows='1'></Input></Col>
            <Col><Button type="submit" color="primary">Remove Devices</Button></Col>
          </FormGroup>
        </Form>
      </Container>
    );
  }
}

export default DistributorDevices;
