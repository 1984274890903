import React, { Component } from "react";
import {
  Container, Col,
  Form, FormGroup,
  Input, Button,
  Table
} from 'reactstrap';
import { APIClient } from "../Client";

class Distributors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distributors: [],
      ready: false
    };
  }

  componentDidMount() {
    APIClient.then(() => {
      this.getDistributors();
      this.setState({ ready: true });
    });
  }

  getDistributorDetails = (id) => {
    APIClient.api['distributors'].list_distributor_details({ distributorId: id })
      .then((res) => {
        //console.log(res.obj.data);
        this.setState({
          distributors: this.state.distributors.concat(res.obj.data)
        });
        APIClient.storeDistributors(this.state.distributors);
      })
      .catch((err) => {
        console.log(err.response, err);
      });
  }

  getDistributors = () => {
    if (!APIClient.token) {
      return;
    }
    if (!APIClient.entities.distributors) {
      APIClient.api['distributors'].list_distributors()
        .then((res) => {
          //console.log(res);
          var distributors = res.obj.data.distributors.sort((a, b) => a - b);
          this.setState({ distributors: [] });
          for(var i = 0; i < distributors.length; i++) {
            this.getDistributorDetails(distributors[i]);
          }
        })
        .catch((err) => {
          console.log(err.response, err);
        });
    } else {
      this.setState({ distributors: APIClient.entities.distributors });
    }
  }

  handleUpdateDistributor = (event) => {
    event.preventDefault();
    if (!APIClient.token) {
      return;
    }
    var distributor = {
      name: event.target.dname.value,
      admins: event.target.admins.value.split(','),
      description: event.target.description.value
    };
    if ((distributor.name.length < 3) || distributor.description.length < 3) {
      console.log('not valid distributor info');
      return; // not valid
    }
    //console.log(distributor);
    APIClient.api['distributors'].create_distributors({ distributor : distributor})
      .then((res) => {
        console.log(res.obj);
        // force refresh list
        delete APIClient.entities.distributors;
        this.getDistributors();
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );
    //console.log('distributors', this.state.distributors);
    return (
      <Container fluid>
        { this.state && this.state.ready &&
        <Form onSubmit={this.handleUpdateDistributor}>
          <FormGroup row>
            <Col sm={2}>
              <Input type="text" name="dname" placeholder="Name"></Input>
            </Col>
            <Col sm={2}>
              <Input type="text" name="admins" placeholder="Admins"></Input>
            </Col>
            <Col sm={3}>
              <Input type="text" name="description" placeholder="Description"></Input>
            </Col>
            <Col sm={1}>
              <Button type="submit" color="primary">Add | Update</Button>
            </Col>
          </FormGroup>
        </Form>
        }
        <hr/>
        { this.state && this.state.distributors &&
          <Table bordered hover size="sm">
            <thead className="text-center">
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Description</th>
                <th>Created</th>
                <th>Admins</th>
              </tr>
            </thead>
            <tbody>
              { this.state.distributors.map(distributor => (
                <tr key={distributor.distributorId}>
                  <th className="text-center">{distributor.distributorId}</th>
                  <td>{distributor.name}</td>
                  <td>{distributor.description}</td>
                  <td>{(new Date(distributor.creationDate)).toLocaleString()}</td>
                  <td><pre>{JSON.stringify(distributor.admins, null, 2)}</pre></td>
                </tr>
              )) }
            </tbody>
          </Table>
        }
      </Container>
    );
  }
}

export default Distributors;
