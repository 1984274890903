import React, { Component } from "react";
import {
  Container, Col,
  Form, FormGroup,
  Input, Button,
  Table
} from 'reactstrap';
import { APIClient } from "../Client";


class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      ready: false
    };
  }

  componentDidMount() {
    APIClient.then(() => {
      this.getGroups();
      this.setState({ ready: true });
    });
  }

  getGroupDetails = (id) => {
    APIClient.api['groups'].list_group_details({ groupId: id })
      .then((res) => {
        //console.log(res.obj.data);
        this.setState({
          groups: this.state.groups.concat(res.obj.data)
        });
        APIClient.storeGroups(this.state.groups);
      })
      .catch((err) => {
        console.log(err.response, err);
      });
  }

  getGroups = () => {
    if (!APIClient.token) {
      return;
    }
    if (!APIClient.entities.groups) {
      APIClient.api['groups'].list_groups()
        .then((res) => {
          //console.log(res.obj.data);
          var groups = res.obj.data.groups.sort((a, b) => a - b);
          this.setState({ groups: [] });
          for(var i = 0; i < groups.length; i++) {
            this.getGroupDetails(groups[i]);
          }
        })
        .catch((err) => {
          console.log(err.response, err);
        });
    } else {
      this.setState({ groups: APIClient.entities.groups });
    }
  }

  handleUpdateGroup = (event) => {
    event.preventDefault();
    if (!APIClient.token) {
      return;
    }
    var group = {
      name: event.target.gname.value,
      admins: event.target.admins.value.split(','),
      description: event.target.description.value
    };
    console.log('group', group);
    if ((group.name.length < 3) || group.description.length < 3) {
      console.log('not valid group info');
      return; // not valid
    }
    APIClient.api['groups'].create_group({ group: group })
      .then((res) => {
        console.log(res.obj);
        // force refresh list
        delete APIClient.entities.groups;
        this.getGroups();
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );
    return (
      <Container fluid>
        {this.state && this.state.ready &&
        <Form onSubmit={this.handleUpdateGroup}>
          <FormGroup row>
            <Col sm={2}>
              <Input type="text" name="gname" placeholder="Name"></Input>
            </Col>
            <Col sm={2}>
              <Input type="text" name="admins" placeholder="Admins"></Input>
            </Col>
            <Col sm={3}>
              <Input type="text" name="description" placeholder="Description"></Input>
            </Col>
            <Col sm={1}>
              <Button type="submit" color="primary">Add | Update</Button>
            </Col>
          </FormGroup>
        </Form>
        }
        <hr/>
        { this.state && this.state.groups &&
          <Table bordered hover size="sm">
            <thead className="text-center">
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Description</th>
                <th>Created</th>
                <th>Admins</th>
              </tr>
            </thead>
            <tbody>
              { this.state.groups.map(group => (
                <tr key={group.groupId}>
                  <th className="text-center">{group.groupId}</th>
                  <td>{group.name}</td>
                  <td>{group.description}</td>
                  <td>{(new Date(group.creationDate)).toLocaleString()}</td>
                  <td><pre>{JSON.stringify(group.admins, null, 2)}</pre></td>
                </tr>
              )) }
            </tbody>
          </Table>
        }
      </Container>
    );
  }
}

export default Groups;
