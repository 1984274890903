import React, { Component } from "react";
import {
  Container,
  Table,
  Button
} from 'reactstrap';
import { APIClient } from "../Client";


class UsersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      ready: false
    };
  }

  componentDidMount() {
    APIClient.then(() => {
      this.getUsers();
      this.setState({ ready: true });
    });
  }

  getUsers = () => {
    if (!APIClient.token) {
      return;
    }

    //console.log('APIClient.entities.users', APIClient.entities.users);
    if (!APIClient.entities.users) {
      APIClient.api['users'].list_users()
        .then((res) => {
          //console.log(res);
          var users_ids = res.obj.data.users.sort((a, b) => a - b);
          var users = [];
          for (var i in users_ids) {
            users.push({'id': users_ids[i]});
          }
          //console.log(users);
          APIClient.storeUsers(users);
          this.getUsers(); // call again
        })
        .catch((err) => {
          console.log(err.response, err);
        });
    } else {
      this.setState({users: APIClient.entities.users});
      for (var i in APIClient.entities.users) {
        var user = APIClient.entities.users[i];
        if (!('email' in user)) {
          this.getUserDetails(user.id);
        }
      }
    }
  }

  getUserDetails = (id) => {
    APIClient.api['users'].list_user_details({ userId: id })
      .then((res) => {
        //console.log(res.obj.data);
        var idx = APIClient.entities.users.findIndex(u => u.id === id);
        APIClient.entities.users[idx] = res.obj.data
        APIClient.storeUsers(APIClient.entities.users);
        this.setState({users: APIClient.entities.users});
      })
      .catch((err) => {
        console.log(err.response, err);
      });
  }

  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );

    return (
      <Container fluid>
        { this.state && this.state.users &&
          <h5 className="text-center">{this.state.users.length} Users</h5>
        }
        { this.state && this.state.users &&
          <div className="table-responsive" style={{display: 'block', maxHeight: '40em', overflow: 'auto'}}>
            <Table bordered hover size="sm">
              <thead className="text-center">
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Sign Up</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                </tr>
              </thead>
              <tbody>
                { this.state.users.map(user => (
                  <tr key={user.id}>
                    <th className="text-center">{user.id}</th>
                    <td>{user.email}</td>
                    <td>{(new Date(user.signupDate)).toLocaleString()}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                  </tr>
                )) }
              </tbody>
            </Table>
          </div>
        }
      </Container>
    );
  }
}

export default UsersDetails;
