import React, { Component } from "react";
import {
  Container, Col,
  Form, FormGroup, FormText,
  Label, Input, Button,
  ListGroup, ListGroupItem, Badge
} from 'reactstrap';
import { APIClient } from "./Client";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      about: undefined,
      ready: false
    };
  }

  componentDidMount() {
    APIClient.then(() => {
      this.getApiInfo();
      this.setState({ ready: true });
    });
  }

  getApiInfo = () => {
    if (!APIClient.about && APIClient.token) {
      APIClient.api['about'].list_api_details()
        .then((res) => {
          APIClient.about = res.obj.data;
          this.setState({ about: APIClient.about });
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    } else {
      this.setState({ about: APIClient.about });
    }
  }

  handleSignin = (event) => {
    event.preventDefault();
    var credentials = {
      apiKey: event.target.apikey.value,
      username: event.target.email.value, // same as email
      password: event.target.password.value,
    };
    //console.log(credentials);
    APIClient.api['api-users'].sign_in({ credential: credentials })
      .then((res) => {
        //console.log(res);
        APIClient.storeToken(res.obj.data); // sign-in token response
        this.getApiInfo();
        this.props.redrawParent();
      })
      .catch((err) => {
        console.log(err.response);
        sessionStorage.clear();
      });
  }

  render() {
    //console.log('token', APIClient.token);
    return (
      <Container>
        {this.state && this.state.ready && (!APIClient.token) &&
          <Form onSubmit={this.handleSignin}>
            <FormGroup row>
              <Label for="input-apikey" sm={1}>API Key</Label>
              <Col sm={4}>
                <Input type="text" name="apikey" id="input-apikey" placeholder="API Key"/>
              </Col>
              <FormText>(a) API key or (b) email + password</FormText>
            </FormGroup>
            <FormGroup row>
              <Label for="input-email" sm={1}>E-mail</Label>
              <Col sm={3}>
                <Input type="email" name="email" id="input-email" placeholder="user@domain.com" />
              </Col>
              <Label for="input-password" sm={1}>Password</Label>
              <Col sm={3}>
                <Input type="password" name="password" id="input-password" placeholder="********" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ size: 2, offset: 1 }}>
                <Button type="submit" color="primary">Sign-in</Button>
              </Col>
            </FormGroup>
          </Form>
        }
        {this.state && this.state.about && APIClient.token &&
          <ListGroup>
            <ListGroupItem>{this.state.about.text}  <Badge>{this.state.about.core}</Badge></ListGroupItem>
            <ListGroupItem>EdgeIQ  <Badge>{this.state.about.edgeiq}</Badge></ListGroupItem>
            <ListGroupItem>PowerSave  <Badge>{this.state.about.powersave}</Badge></ListGroupItem>
            <ListGroupItem>ESensor  <Badge>{this.state.about.esensor}</Badge></ListGroupItem>
          </ListGroup>
        }
      </Container>
    );
  }
}

export default Home;
