import React, { Component } from "react";
import {
  Button
} from 'reactstrap';
import { APIClient } from "../Client";


class DeviceDetails extends Component {
  render() {
    if (!APIClient.token)
      return (
        <Button color="link" onClick={() => this.props.history.push('/')}>Sign-in</Button>
      );
    if (!APIClient.entities.distributors)
      return (
        <Button color="link" onClick={() => this.props.history.push('/distributors')}>Get Distributors</Button>
      );
    return (
      <div>
        <p>to do: Device Details</p>
      </div>
    );
  }
}

export default DeviceDetails;
